import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageGenerationService} from "../../../services/image-generation.service";
import {ClientsService} from "../../../services/clients.service";
import {firstValueFrom, Subject, Subscription, takeUntil} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {downloadUrl, getClientFromRoute, openSnackBar} from "../../../utils";
import {
    PreviewGenDialogComponent,
    PreviewGenDialogData
} from "../../../dialogs/preview-gen-dialog/preview-gen-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import {LibraryService} from "../../../services/library.service";
import {Client} from "../../../models/client-management";
import {LocalStorageService} from "../../../services/local-storage.service";

interface Resolution {
    id: string;
    width: number;
    height: number;
    description: string;
}

interface ResolutionGroup {
    disabled?: boolean;
    name: string;
    resolutions: Resolution[];
}

interface GenImage {
    libraryId: number | null;
    url: string;
    info: string;
    isBusy: boolean;
    guid: string;
    width: number;
    height: number;
    prompt: string;
    fillDetails: boolean;
    improveFaces: boolean;
}

interface GenSettings {
    fillDetails: boolean;
    improveFaces: boolean;
    prompt: string;
    resolution: Resolution;
    images: GenImage[];
}

@Component({
    selector: 'app-image-generation',
    templateUrl: './image-generation.component.html',
    styleUrls: ['./image-generation.component.scss']
})
export class ImageGenerationComponent implements OnInit, OnDestroy {

    prompt = '';
    images: GenImage[] = [];
    isBusy = false;
    serial: number = 0;

    fillDetails: boolean = false;
    improveFaces: boolean = false;
    client: Client | null = null;
    routeSubscription: Subscription | null = null;

    constructor(private generator: ImageGenerationService, private router: Router, private activatedRoute: ActivatedRoute,
                private clientsService: ClientsService, private libraryService: LibraryService,
                private snackBar: MatSnackBar, private dialog: MatDialog,
                private storage: LocalStorageService) {
    }


    ngOnInit(): void {

        // Select Common Large Square
        this.resolutionsControl.setValue("1");

        // Monitor route changes
        this.routeSubscription = this.activatedRoute.params.subscribe(params => {
            let clientId = params['clientId'];
            console.log("Client from route subscribe: ", clientId);
            if (clientId) {
                this.clientsService.get(+clientId).then(client => {
                    this.client = client;

                    if (!this.client) {
                        this.router.navigate(['/dashboard']);
                        return;
                    }

                    // Load saved data
                    const settings = this.storage.getItem('image-gen-' + this.client.id) as GenSettings | null;

                    if (settings) {
                        this.fillDetails = settings.fillDetails;
                        this.improveFaces = settings.improveFaces;
                        this.prompt = settings.prompt;
                        this.images = settings.images;

                        // Select resolution
                        this.resolutionsControl.setValue(settings.resolution.id);

                    }

                });
            } else {
                this.router.navigate(['/dashboard']);
                return;
            }
        });

    }

    ngOnDestroy(): void {
        if (this.routeSubscription)
            this.routeSubscription.unsubscribe();
    }

    public async generateImages() {

        if (this.isBusy)
            return;

        if (this.prompt.trim() === '') {
            openSnackBar(this.snackBar, "Please enter a prompt", "close");
            return;
        }

        if (this.client == null) {
            console.error("Client is null");
            return;
        }

        this.isBusy = true;

        let val = this.resolutionsControl.value;

        let resolution = this.resolutionGroups.flatMap(g => g.resolutions)
            .find(r => r.id === val);

        if (!resolution) {
            console.error("Resolution not found");
            return;
        }

        let group = this.resolutionGroups.find(g => g.resolutions.indexOf(resolution!) !== -1);

        if (!group) {
            console.error("Group not found");
            return;
        }

        try {

            const res = await this.generator.generateImages(this.client.id,
                this.prompt, resolution.width, resolution.height, this.fillDetails, this.improveFaces);

            for (let i = 0; i < res.length; i++) {
                const img = {
                    url: res[i].url,
                    info: `${group.name} — ${resolution.description} (${resolution.width} x ${resolution.height})`,
                    isBusy: false,
                    guid: (this.serial++).toString(),
                    width: resolution.width,
                    height: resolution.height,
                    libraryId: null,
                    prompt: this.prompt,
                    fillDetails: this.fillDetails,
                    improveFaces: this.improveFaces
                };

                // Add as first
                this.images.unshift(img);
            }

            this.saveSettings();

        } catch (e) {
            console.error(e);
            // Mostrare toaster qui

            openSnackBar(this.snackBar, "Error generating images", "close");
        }

        this.isBusy = false;

    }

    public saveSettings() {

        if (this.client == null) return;

        // Save data
        const settings: GenSettings = {
            fillDetails: this.fillDetails,
            improveFaces: this.improveFaces,
            prompt: this.prompt,
            images: this.images,
            resolution: this.resolutionGroups.flatMap(g => g.resolutions)
                .find(r => r.id === this.resolutionsControl.value)!
        };

        this.storage.setItem('image-gen-' + this.client.id, settings);
    }

    public async removeImage(image: GenImage) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: {
                title: 'Confirm Delete',
                message: 'Are you sure you want to discard this image?',
                confirmText: 'Yes',
                cancelText: 'No',
                cancelColor: 'primary',
                confirmColor: 'warn'
            }
        });

        let result = await firstValueFrom(dialogRef.afterClosed());

        if (!result)
            return;

        this.images = this.images.filter(item => item.guid !== image.guid);

        this.saveSettings();

        openSnackBar(this.snackBar, "Image discarded", "close")
    }

    async previewImage(image: GenImage) {

        if (image.isBusy) return;

        console.log("Preview image: ", image);

        const data: PreviewGenDialogData = {
            url: image.url,
            info: image.info,
            width: image.width,
            height: image.height,
            prompt: image.prompt,
            fillDetails: image.fillDetails,
            improveFaces: image.improveFaces
        };

        const dialogRef = this.dialog.open(PreviewGenDialogComponent, {
            width: 'auto',
            data: data
        });

        await firstValueFrom(dialogRef.afterClosed());
    }

    downloadImage(image: GenImage) {

        if (image.isBusy) return;

        image.isBusy = true

        // TODO: Hardcoded type
        const name = `image_${image.guid}.webp`;
        downloadUrl(image.url, name);

        image.isBusy = false;
    }

    /*downloadImage(image: GenImage, webp: string) {

        if (image.isBusy) return;

        image.isBusy = true
        // Wait 1 second
        setTimeout(() => {
            image.isBusy = false;
        }, 1000);
    }*/

    async addToLibrary(image: GenImage) {

        if (image.isBusy) return;
        if (this.client == null) return;

        console.log("Add to library: ", image);

        image.isBusy = true;

        try {

            const res = await this.clientsService.addMedia(this.client.id, image.url);

            console.log("Added to library: ", res);

            image.libraryId = res.id;

            image.isBusy = false;

            openSnackBar(this.snackBar, "Image added to library", "close");

        } catch (e) {
            image.isBusy = false;
            console.error(e);
            openSnackBar(this.snackBar, "Error adding image to library", "close");
        }

    }

    async removeFromLibrary(image: GenImage) {

        if (image.isBusy) return;
        if (image.libraryId == null) return;

        console.log("Remove from library: ", image);

        image.isBusy = true;

        try {

            await this.libraryService.removeMedia(image.libraryId);

            image.libraryId = null;

            image.isBusy = false;
            openSnackBar(this.snackBar, "Image removed from library", "close");
        } catch (e) {
            image.isBusy = false;
            console.error(e);
            openSnackBar(this.snackBar, "Error removing image from library", "close");
        }
    }

    resolutionsControl = new FormControl('');
    resolutionGroups: ResolutionGroup[] = [
        {
            "name": "Common",
            "resolutions": [
                {id: "0", width: 512, height: 512, "description": "Square"},
                {id: "1", width: 1024, height: 1024, "description": "Large Square"}
            ]
        },
        {
            "name": "Facebook",
            "resolutions": [
                {id: "2", width: 170, height: 170, "description": "Profile Photo"},
                {id: "3", width: 1200, height: 628, "description": "Landscape"},
                {id: "4", width: 628, height: 1200, "description": "Portrait"},
                {id: "5", width: 1200, height: 1200, "description": "Square"},
                {id: "6", width: 1080, height: 192, "description": "Stories"},
                {id: "7", width: 851, height: 315, "description": "Cover Photo"}
            ]
        },
        {
            "name": "Instagram",
            "resolutions": [
                {id: "8", width: 320, height: 320, "description": "Profile Photo"},
                {id: "9", width: 1080, height: 566, "description": "Landscape"},
                {id: "10", width: 1080, height: 1350, "description": "Portrait"},
                {id: "11", width: 1080, height: 1080, "description": "Square"},
                {id: "12", width: 1080, height: 1920, "description": "Stories"}
            ]
        },
        {
            "name": "Twitter",
            "resolutions": [
                {id: "13", width: 400, height: 400, "description": "Profile Photo"},
                {id: "14", width: 1600, height: 900, "description": "Landscape"},
                {id: "15", width: 1080, height: 1350, "description": "Portrait"},
                {id: "16", width: 1080, height: 1080, "description": "Square"},
                {id: "17", width: 1500, height: 500, "description": "Cover Photo"}
            ]
        },
        {
            "name": "LinkedIn",
            "resolutions": [
                {id: "18", width: 400, height: 400, "description": "Profile Photo"},
                {id: "19", width: 1200, height: 627, "description": "Landscape"},
                {id: "20", width: 627, height: 1200, "description": "Portrait"},
                {id: "21", width: 1080, height: 1080, "description": "Square"},
                {id: "22", width: 1584, height: 396, "description": "Cover Photo"}
            ]
        },
        {
            "name": "TikTok",
            "resolutions": [
                {id: "23", width: 200, height: 200, "description": "Profile Photo"},
                {id: "24", width: 1920, height: 1080, "description": "Landscape"},
                {id: "25", width: 1080, height: 1920, "description": "Portrait"},
                {id: "26", width: 1080, height: 1080, "description": "Square"},
                {id: "27", width: 1080, height: 1920, "description": "Stories"}
            ]
        }
    ];

}
